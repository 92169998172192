import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, IconButton } from "@mui/material";

import FilePreviewGenerator from "../../components/filePreviewGenerator/filePreviewGenerator";
import pathUrlManager from "../../utils/pathUrlManager";

import CheckIconSvg from "../../assets/icon/check-icon-blue.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete-selection-icon-white.svg";
import { ReactComponent as DownloadIconWhite } from "../../assets/icon/download-icon-white.svg";
import NotCheckIconSvg from "../../assets/icon/empty-selection-icon-blue.svg";
import keycloak from "../../keycloak";

import { FiEdit2 } from 'react-icons/fi';

export const Folder = ({
                           folder,
                           location,
                           setCardHover,
                           downloadFolder,
                           deleteFolder,
                           viewMode,
                           deleteModal,
                           checkedFolder,
                           editName,
                           readOnly,
                       }) => {
    const currentLocation = useLocation();
    const isMediakitPage = currentLocation.pathname === '/mediakit';

    return pathUrlManager.formatNameFolder(folder.Prefix.slice(0, -1)) !== 'mf-tumbnail' ? (
        viewMode ? (
            <div className="card-cover">
                <div
                    className="card p-5"
                    onMouseOver={() => setCardHover(true)}
                    onMouseLeave={() => setCardHover(false)}
                >
                    <div className="selector">
                        {folder.checked ? (
                            <img
                                src={CheckIconSvg}
                                className="checkbox"
                                onClick={() => checkedFolder(folder, folder.checked)}
                                alt="checked icon"
                            />
                        ) : (
                            <img
                                src={NotCheckIconSvg}
                                className="checkbox"
                                onClick={() => checkedFolder(folder, folder.checked)}
                                alt="not checked icon"
                            />
                        )}
                    </div>
                    <Link
                        to={`${pathUrlManager.prefixGoTo(folder.Prefix, location)}`}
                        className="preview"
                    >
                        <FilePreviewGenerator folder={true} />
                    </Link>
                    <div className="title">
                        <div className="text">
                            {pathUrlManager.formatNameFolder(folder.Prefix.slice(0, -1))}
                        </div>
                    </div>
                    <div className="action">
                        {/* Mostra il pulsante di download solo se non siamo sulla pagina mediakit */}
                        {!isMediakitPage && (
                            <Button
                                variant="contained"
                                onClick={() => downloadFolder(folder)}
                                startIcon={<DownloadIconWhite className="icon-size-small" />}
                                className="primary-button blue-active-bg max-width"
                            >
                                Download
                            </Button>
                        )}

                        {!!keycloak.authenticated && (
                            <Button
                                variant="contained"
                                onClick={() => deleteModal(folder, deleteFolder)}
                                startIcon={<DeleteIcon className="icon-size-small" />}
                                style={{ marginTop: readOnly ? "3%" : "" }}
                                className={`primary-button danger-bg max-width ${
                                    readOnly ? "" : "read-only"
                                }`}
                            >
                                Delete
                            </Button>
                        )}
                        {!!keycloak.authenticated && (
                            <Button
                                variant="contained"
                                onClick={() => editName(folder.Prefix, true)}
                                startIcon={<FiEdit2 className="icon-size-small" />}
                                style={{ marginTop: readOnly ? "3%" : "" }}
                                className={`primary-button warning-bg max-width ${
                                    readOnly ? "" : "read-only"
                                }`}
                            >
                                Duplicate
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div className="table-row white-table-row">
                <div className="name-header">
                    <div className="check-list-container">
                        {folder.checked ? (
                            <img
                                src={CheckIconSvg}
                                className="checkbox pointer"
                                onClick={() => checkedFolder(folder, folder.checked)}
                                alt="checked icon"
                            />
                        ) : (
                            <img
                                src={NotCheckIconSvg}
                                className="checkbox pointer"
                                onClick={() => checkedFolder(folder, folder.checked)}
                                alt="not checked icon"
                            />
                        )}
                    </div>
                    <div className="check-list-container">
                        <FilePreviewGenerator folder={true} />
                    </div>
                    <Link
                        to={`${pathUrlManager.prefixGoTo(folder.Prefix, location)}`}
                        className="text-header medium-text black-text"
                    >
                        {pathUrlManager.formatNameFolder(folder.Prefix.slice(0, -1))}
                    </Link>
                </div>
                <Link
                    to={`${pathUrlManager.prefixGoTo(folder.Prefix, location)}`}
                    className="size-header medium-text black-text"
                ></Link>
                <Link
                    to={`${pathUrlManager.prefixGoTo(folder.Prefix, location)}`}
                    className="modify-header medium-text black-text"
                ></Link>
                <div className="download-header">
                    {/* Mostra il pulsante di download solo se non siamo sulla pagina mediakit */}
                    {!isMediakitPage && (
                        <IconButton
                            variant="contained"
                            style={{ marginRight: "5px" }}
                            onClick={() => downloadFolder(folder)}
                            className="square-primary-button blue-active-bg"
                        >
                            <DownloadIconWhite className="icon-size-small" />
                        </IconButton>
                    )}
                    {!!keycloak.authenticated && (
                        <IconButton
                            variant="contained"
                            onClick={() => deleteModal(folder, deleteFolder)}
                            className={`square-primary-button danger-bg ${
                                readOnly ? "" : "read-only"
                            }`}
                        >
                            <DeleteIcon className="icon-size-small" />
                        </IconButton>
                    )}
                </div>
            </div>
        )
    ) : null;
};
